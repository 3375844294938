<template>
  <div id="about" :class="$mq">
    <div class="description">
      <h2>{{ $t("about.title") }}</h2>
      <p>{{ $t("about.description") }}</p>
    </div>
    <div class="infos">
      <h2>{{ $t("infos.title") }}</h2>
      <div class="division">
        <div class="keys">
          <p>AGE:</p>
          <p>EMAIL:</p>
          <p>{{ $t("phone") }}</p>
          <p>{{ $t("languages.title") }}</p>
        </div>
        <div class="values">
          <p>23</p>
          <p>quentin.louis21@gmail.com</p>
          <p>0474.60.45.45</p>
          <p>{{ $t("languages.value") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped>
#about {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 50px;
  max-width: 300px;
  width: 300px;
  font-size: 0.8rem;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  h2 {
    margin: 16px 0px;
  }

  .description p {
    text-align: justify;
  }

  &.desktop {
    max-width: 775px;
    width: 775px;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 0.9rem;

    .description {
      width: 45%;
    }

    .infos {
      width: 45%;
    }
  }

  .infos {
    .division {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .keys {
        width: 30%;
        font-weight: bold;
      }

      .values {
        width: 65%;
      }
    }
  }
}
</style>