<template>
  <div id="skills" :class="$mq">
    <div class="skills">
      <div class="skill">
        <h3>{{ $t("skills.backend") }} :</h3>
        <ul>
          <li>Dynamics 365 / Power Platform</li>
          <li>C# / ASP.NET Core MVC / WPF / Unity C#</li>
          <li>PHP / Laravel / Lumen</li>
          <li>Java / Spring Boot</li>
          <li>Android Natif (mobile)</li>
        </ul>
      </div>
      <div class="skill">
        <h3>{{ $t("skills.frontend") }} :</h3>
        <ul>
          <li>HTML5 / CSS3</li>
          <li>React JS / React Native</li>
          <li>JS / Vue JS / Vanilla JS / Aurelia JS</li>
          <li>Jquery</li>
        </ul>
      </div>
      <div class="skill">
        <h3>{{ $t("skills.atlassian") }} :</h3>
        <ul>
          <li>Jira</li>
          <li>Confluence</li>
          <li>Bamboo</li>
        </ul>
      </div>
    </div>
    <div class="skills">
      <div class="skill">
        <h3>{{ $t("skills.database") }} :</h3>
        <ul>
          <li>MySQL</li>
          <li>SQL Server</li>
          <li>MongoDB</li>
          <li>SQL Developer</li>
          <li>Dataverse</li>
        </ul>
      </div>
      <div class="skill">
        <h3>Infrastructure :</h3>
        <ul>
          <li>Linux V.M.</li>
          <li>Windows Active Directory</li>
          <li>Docker</li>
        </ul>
      </div>
      <div class="skill">
        <h3>Versioning :</h3>
        <ul>
          <li>Github</li>
          <li>Gitlab</li>
          <li>Azure DevOps</li>
        </ul>
      </div>
      <div class="skill">
        <h3>{{ $t("skills.other") }} :</h3>
        <ul>
          <li>{{ $t("skills.agile") }}</li>
          <li>{{ $t("skills.uml") }}</li>
          <li>Power Automate</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skills",
};
</script>

<style lang="scss" scoped>
#skills {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 50px;
  max-width: 300px;
  width: 300px;
  font-size: 0.8rem;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  h2 {
    margin: 16px 0px;
  }

  &.desktop {
    max-width: 775px;
    width: 775px;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 0.9rem;

    .skills {
      width: 45%;
    }
  }

  .skill {
    ul {
      margin-top: 0;
      list-style-type: circle;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>