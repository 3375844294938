<template>
    <div class="page">
        <div class="content">
            <Header></Header>
            <Content></Content>
        </div>
    </div>    
</template>

<script>
import Header from '@/components/firstPage/Header.vue'
import Content from '@/components/firstPage/Content.vue'

export default {
    name :  "FirstPage",
    components : {
        Header,
        Content
    }
}
</script>

<style scoped>
.page {
    display: flex;
    height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
    margin-bottom: 20px;
    box-shadow: 10px 5px 5px grey;
    border-radius: 15px;
    border: 2px solid #174062;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
</style>