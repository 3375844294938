import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/locales/index'
import VueMq from 'vue-mq'

Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints : {
    'mobile' : 800,
    'desktop' : Infinity
  },
  default : 'mobile'
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
