import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from './fr.json'
import en from './en.json'

Vue.use(VueI18n);

const languages = {
    fr : fr,
    en : en
};

const messages = Object.assign(languages);

const defaultLocale = 'fr';

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale : 'fr',
    messages
});

export {i18n};
