<template>
    <div class="page">
        <div class="content">
            <Experiences></Experiences>
        </div>
    </div>    
</template>

<script>

import Experiences from '@/components/secondPage/Experiences'

export default {
    name :  "SecondPage",
    components : {
        Experiences
    }
}
</script>

<style scoped>
.page {
    display: flex;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    margin-bottom: 20px;
    box-shadow: 10px 5px 5px grey;
    border-radius: 15px;
    border: 2px solid #174062;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding : 5px;
}
</style>