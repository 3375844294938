<template>
  <div id="certifications" :class="$mq">
    <h2>{{ $t("certifications.title") }}</h2>

    <div class="certifications" :class="$mq">
      <img
        :src="src('certif-pl400')"
        alt="certification pl-400 Microsoft"
        title="From November 2021 to November 2023"
        :class="$mq"
      />
      <img
        :src="src('certif-pl200')"
        alt="certification pl-200 Microsoft"
        title="From December 2021 to December 2023"
        :class="$mq"
      />
      <img
        :src="src('certif-pl600')"
        alt="certification pl-600 Microsoft"
        title="From January 2022 to December 2023"
        :class="$mq"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "certifications",
  methods: {
    src(image) {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./" + image + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.certifications {
  display: flex;
  flex-direction: row;
  width: 300px;
  max-width: 300px;
  justify-self: center;
  margin: auto;

  &.desktop {
    max-width: 775px;
    width: 775px;
  }

  img {
    max-width: 100px;
    margin: 20px;
    object-fit: contain;

    &.desktop {
      max-width: 150px;
    }
  }
}
</style>