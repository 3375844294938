<template>
    <div id="navbarMobile">
        <img :src="getImgUrl()" alt="image of flag" class="flag" @click="changeLanguage"/>
    </div>
</template>
<script>
export default {
    name: "NavbarMobile",
    data : () => {
        return {
            'image' : 'fr'
        }
    },
    methods : {
        changeLanguage(){
            if(this.image == 'fr'){
                this.image = 'en';
            }else{
                this.image = 'fr';
            }
            this.$i18n.locale = this.image;
        },
        getImgUrl(){
            var images = require.context('../../assets/', false, /\.png$/);
            if(this.image == 'en'){
                return images('./fr.png');
            }else{
                return images('./en.png');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
#navbarMobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    .flag {
        width: 20px;
        height: 20px;
        margin-left: auto;
        margin-right: 10px;
        padding: 5px;
                
        &:hover {
            cursor: pointer;
        }
    }
}
</style>