<template>
    <div id="description">
        <h2>Quentin LOUIS</h2>
        <h3>{{ $t('developer') }}</h3>
    </div>
</template>

<script>
export default {
    name: "Description"
}
</script>

<style lang="scss" scoped>
#description {
    display: flex;
    align-self: center;
    flex-direction: column;
    color: white;

    h2 {
        margin-bottom: 5px;
    }
    
    h3 {
        margin-bottom: 10px;
    }
}
</style>