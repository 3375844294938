<template>
    <div class="cv-content" :class="$mq">
        <div class="left">
            <div class="contact">
                <h2>{{ $t('contact') }}</h2>
                <div class="icons">
                    <a href="mailto:quentin.louis21@gmail.com" title="quentin.louis21@gmail.com"><i class="fa fa-envelope" :class="$mq"></i></a>
                    <a href="tel:0474604545" title="0474604545"><i class="fa fa-phone" :class="$mq"></i></a>
                    <a href="https://www.linkedin.com/in/quentin-louis-b90013176/" target="_blank"><i class="fa fa-linkedin-square" :class="$mq"></i></a>
                </div>
            </div>
            <div class="hobbies">
                <h2>{{ $t('hobbies') }}</h2>
                <div class="icons">
                    <a><i class="fa fa-music" :class="$mq"></i></a>
                    <a><i class="fa fa-code-fork" :class="$mq"></i></a>
                    <a><i class="fa fa-globe" :class="$mq"></i></a>
                    <a><i class="fa fa-gamepad" :class="$mq"></i></a>
                </div>
            </div>

            <div class="languages">
                <h2>{{ $t('languages.title') }}</h2>
                <div class="container">
                    <div class="language">
                        <p>{{ $t('languages.french') }}</p>
                        <div class="stars">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                        </div>
                    </div>
                    <div class="language">
                        <p>{{ $t('languages.english') }}</p>
                        <div class="stars">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                    </div>
                    <div class="language">
                        <p>{{ $t('languages.dutch') }}</p>
                        <div class="stars">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr :class="$mq">
        <div class="right">
            <div class="about">
                <h2>{{ $t('about.title') }}</h2>
                <p :class="$mq">{{ $t('about.description') }}</p>
            </div>

            <h2 :class="$mq" class="h2Skill">{{ $t('skills.title') }}</h2>
            <div class="skills" :class="$mq">
                <div class="skill" :class="$mq">
                        <p :class="$mq">Laravel / Lumen PHP</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">JS / VueJS / Aurelia</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">HTML / CSS3</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">MySQL / PostgreSQL</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">GIT</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">C# .NET / ASP.NET</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">Admin. Linux / Docker</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">{{ $t('skills.analysis') }}</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">Mongo DB / SQL Server</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
                <div class="skill" :class="$mq">
                        <p :class="$mq">JAVA</p>
                        <div class="stars" :class="$mq">
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star active" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                            <a><i class="fa fa-star" :class="$mq"></i></a>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.cv-content {
    display: flex;
    flex-direction: row;
    height: 70%;
    width: 100%;
    color : black;

    &.desktop {
        height: 75%;
    }

    .stars {
        i {
            font-size: 0.9rem;

            &.desktop {
                font-size : 1.2rem;
            }
        }
        .active {
            color : #e7bd42;
        }
    }

    h2 {
        margin : 10px 0;
        font-size : 0.9rem;
        text-decoration: underline;
        height : 10%;
        color : #174062;

        &.desktop {
            margin : 0;
            height : auto
        }
    }

    .left {
        width: 35%;
        overflow-y: auto;
        height : 95%;
        
        .contact {
            display: flex;
            flex-direction: column;
            height: 33%;

            .icons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-left : 5px;
                height: 90%;

                a {
                    display: flex;
                    color : black;
                    width: 33%;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                }
                i {
                    font-size: 1.3rem;
                    color : #174062;

                    &.desktop {
                        font-size: 2rem;
                    }
                }

                &:last-child {
                    margin-right: 5px;
                }
            }
        }

        .hobbies {
            display: flex;
            flex-direction: column;
            height: 33%;

            h2 {
                height : 10%;
            }
            .icons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                height: 90%;

                a {
                    display: flex;
                    color : black;
                    width: 50%;
                    justify-content: center;
                    text-decoration: none;
                    align-items: center;
                }
                i {
                    font-size: 1.3rem;
                    color : #174062;

                    &.desktop {
                        font-size : 2rem;
                    }
                }
            }
        }

        .languages {
            display: flex;
            flex-direction: column;
            height: 33%;
            width: 100%;

            h2 {
                height : 10%;
            }
        
            .container {
                display: flex;
                flex-direction: column;
                height: 90%;

                .language {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: 33%;
                    width: 100%;

                    p {
                        margin: 0 5px 0 0;
                        font-size: 0.8rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .right {
        width: 60%;
        height : 95%;
        overflow-y: auto;

        p {
            margin: 0;
            font-size : 0.7rem;
            text-align: left;

            &.desktop {
                    font-size : 1rem;
            }
        }

        .about {
            padding : 0 10px;
            display: flex;
            flex-direction: column;
            height: 45%;
            overflow-y: auto;
        }

        .h2Skill{
            margin: 10% 0 0 0;
            height : auto;

            &.desktop {
                margin : 0;
            }
        }

        .skills {
            display: flex;
            flex-direction: column;
            height: 45%;
            overflow-y: auto;
            margin-top: 2%;
            padding : 0 10px;

            &.desktop {
                flex-direction: row;
                flex-wrap: wrap;
                margin-top : 0;
                height : 50%;
            }

            .skill {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;

                &.desktop {
                    width : 50%
                }
                p {
                    display: flex;
                    justify-self: flex-start;
                }

                .stars {
                    display: flex;
                    margin-left: auto;

                    &.desktop {
                        margin-right : 10px;
                    }
                }
            }
        }
    }

    hr {
        border: none;
        border-left: 2px solid #174062; 
        height : 90%;  

        &.desktop {
            height : 95%
        }
    }
}
</style>