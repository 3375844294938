<template>
    <div id="navbar" :class="$mq">
      <NavbarDesktop v-if="$mq === 'desktop'"></NavbarDesktop>
      <NavbarMobile v-if="$mq === 'mobile'"></NavbarMobile>
    </div>
</template>

<script>
import NavbarDesktop from './navbar/NavbarDesktop';
import NavbarMobile from './navbar/NavbarMobile';

export default {
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      if(this.$mq == 'desktop'){
        // Any code to be executed when the window is scrolled
        let nav = document.getElementById('navbar');
        if(window.scrollY > 275) nav.classList.add('scrolled');
        else nav.classList.remove('scrolled');
      }
    }
  },
  name: "Navbar",
  components: { NavbarMobile, NavbarDesktop }
}
</script>

<style lang="scss" scoped>
#navbar {
    display: flex;
    flex-direction: row;
    position: relative; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    padding-top: 20px;
    background-color: transparent;
    color: white;

    &.desktop {
      position: fixed;
    }
} 
.scrolled {
    background-color: #174062  !important;
}
</style>