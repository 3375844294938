<template>
    <div class="experience" :class="$mq">
        <div class="image">
            <img :src="src()" alt="Image">
        </div>
        <div class="text">
            <h2>{{ $t('experiences.'+ image + '.title')}}</h2>
            <h3>{{ $t('experiences.'+ image + '.from')}}</h3>
            <p>{{ $t('experiences.'+ image + '.description')}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Experience",
    props: {
        image: {
            type: String,
            required: true
        }
    },
    methods: {
        src() {
            var images = require.context('../../assets/', false, /\.png$/);
            return images('./'+ this.image+ '.png');
        }
    }
}
</script>

<style lang="scss" scoped>
.experience {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 300px;
    width: 300px;
    font-size: 0.8rem;
    box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
    padding: 10px;
    margin-bottom: 25px;

    .image {
        width: 100%;
        
        img {
            object-fit: contain;
            width: 75%;
            max-width: 100%;
            height: 150px;
        }
    }

    .text {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        p {
            text-align: justify;
        }
        
        h2 {
            margin-bottom: 10px;
        }

        h3 {
            margin: 0;
            font-size: 0.75rem;
            color: #808080;
        }
    }

    &.desktop {
        max-width: 775px;
        width: 775px;
        flex-direction: row;
        font-size: 0.9rem;
        max-height: 175px;

        .image {
            width: 25%;

            img {
                height: 175px;
                width: 100%;
            }
        }

        .text {
            width: 75%;
            max-width: 75%;
            padding: 0 15px;
            overflow-y: auto;
            scrollbar-width: thin;
        }
    }
}
</style>