<template>
    <div class="experiences">
        <h2 :class="$mq">{{ $t('experiences.title') }}</h2>

        <div class="experience">
            <h3 :class="$mq">{{ $t('experiences.nsi.title') }}</h3>
            <h4 :class="$mq"><a href="https://www.nsi-sa.be/" target="_blank">{{ $t('experiences.nsi.from') }}</a></h4>
            <p :class="$mq">{{ $t('experiences.nsi.description') }}</p>
        </div>
        
        <div class="experience">
            <h3 :class="$mq">{{ $t('experiences.mega.title') }}</h3>
            <h4 :class="$mq"><a href="https://www.mega.be/fr/" target="_blank">{{ $t('experiences.mega.from') }}</a></h4>
            <p :class="$mq">{{ $t('experiences.mega.description') }}</p>
        </div>

        <div class="experience">
            <h3 :class="$mq">{{ $t('experiences.internship.title') }}</h3>
            <h4 :class="$mq"><a href="http://www.sig.hec.uliege.be/" target="_blank">{{ $t('experiences.internship.from') }}</a></h4>
            <p :class="$mq">{{ $t('experiences.internship.description') }}</p>
        </div>

        <div class="experience">
            <h3 :class="$mq">{{ $t('experiences.salto.title') }}</h3>
            <h4 :class="$mq"><a href="https://www.salto.helmo.be/CMS/index.php" target="_blank">{{ $t('experiences.salto.from') }}</a></h4>
            <p :class="$mq">{{ $t('experiences.salto.description') }}</p>
        </div>

        <div class="experience">
            <h3 :class="$mq">{{ $t('experiences.hackathon.title') }}</h3>
            <h4 :class="$mq"><a href="https://www.wirehack.me/" target="_blank">{{ $t('experiences.hackathon.from') }}</a></h4>
            <p :class="$mq">{{ $t('experiences.hackathon.description') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.experiences {
    display: flex;
    flex-direction: column;
    height : 100%;
    width: 100%;

    color : black;

    h2 {
        font-size : 1.1rem;
        text-decoration: underline;
        margin-bottom: 10px;
        color : #174062;

        &.desktop {
            font-size : 1.3rem;
        }
    }

    .experience {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height : 20%;
        overflow-y: auto;
        margin-bottom: 15px;
        

        h3 {
            font-size: 0.9rem;
            margin: 0;
            color : #174062;

            &.desktop {
                font-size : 1.3rem;
            }
        }

        h4 {
            margin : 0 0 4px 0;
            font-size: 0.7rem;

            &.desktop {
                font-size : 1rem;
            }
            a {
                text-decoration: none;
                color : #215c8c;
            }
        }

        p {
            margin: 0;
            text-align: left;
            font-size : 0.7rem;

            &.desktop {
                font-size : 1rem;
            }
        }
    }
}
</style>