<template>
    <div id="experiences" :class="$mq">
        <h2>{{ $t('experiences.title') }}</h2>

        <Experience image="nsi"></Experience>
        <Experience image="mega"></Experience>
        <Experience image="salto"></Experience>
        <Experience image="hec"></Experience>
        <Experience image="wirehack"></Experience>
    </div>
</template>

<script>
import Experience from "@/components/experiences/Experience";

export default {
    name: "Experiences",
    components: {Experience}
}
</script>

<style lang="scss" scoped>
#experiences {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    h2 {
        margin-top: 0;
    }
}
</style>