<template>
    <div id="cv">
        <!--<FirstPage></FirstPage>
        <SecondPage></SecondPage>-->

        <div class="holder" :class="$mq">
            <Navbar></Navbar>
            <div class="me" :class="$mq"></div>
            <Description></Description>
        </div>

        <About></About>

        <Skills></Skills>

        <Experiences></Experiences>

        <Certifications></Certifications>
    </div>
</template>

<script>

import FirstPage from '@/components/FirstPage'
import SecondPage from '@/components/SecondPage'
import Navbar from "@/components/Navbar";
import Description from "@/components/Description";
import About from "@/components/About";
import Skills from "@/components/Skills";
import Experiences from "@/components/Experiences";
import Certifications from "@/components/Certifications";

export default {
    name : "CV",
    components : {
        FirstPage,
        SecondPage,
        Navbar,
        Description,
        About,
        Skills,
        Experiences,
        Certifications
    }
}
</script>

<style lang="scss" scoped>
#cv {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: black;

    .holder {
        display: flex;
        flex-direction: column;
        background: url("../assets/background.jpg") no-repeat;
        background-size: cover;
        background-position: 80%;
        height: 275px;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        &.desktop {
            height: 350px;
        }

        .me {

            display: flex;
            margin-top: 25px;
            align-self: center;

            background: url("../assets/me.jpg") no-repeat;
            height: 100px;
            width: 100px;
            background-size: cover;
            background-position: 80%;
            border-radius: 50%;

            &.desktop {
                width: 150px;
                height: 150px;
                margin-top: 75px;
            }
        }
    }
}
</style>