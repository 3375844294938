<template>
    <div id="navbarDesktop">
        <div class="links">
            <a href="#about">{{ $t('links.about') }}</a>
            <a href="#skills">{{ $t('links.skills') }}</a>
            <a href="#experiences">{{ $t('links.experiences') }}</a>
            <a href="#certifications">Certifications</a>
        </div>
        
        <img :src="getImgUrl()" alt="image of flag" class="flag" @click="changeLanguage"/>
    </div>
</template>

<script>
export default {
    name: "NavbarDesktop",
    data : () => {
        return {
            'image' : 'fr'
        }
    },
    methods : {
        changeLanguage(){
            if(this.image == 'fr'){
                this.image = 'en';
            }else{
                this.image = 'fr';
            }
            this.$i18n.locale = this.image;
        },
        getImgUrl(){
            var images = require.context('../../assets/', false, /\.png$/);
            if(this.image == 'en'){
                return images('./fr.png');
            }else{
                return images('./en.png');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#navbarDesktop {
    display: flex;
    flex-direction: row;
    width: 100%;

    .links {
        
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-bottom: 10px;

        a {
           margin-right: 20px;
           color: white;
           text-decoration: none;
           padding: 5px;
        }

        a:link {
            color: white;
        }

        a:visited {
            color: white;
        }

        a:hover {
            background-color: #C0C0C0;
            border-radius: 5px;
        }

        a:active {
            color: white;
        }
    }
    .flag {
        width: 20px;
        height: 20px;
        margin-right: 10%;
        padding: 5px;
            
        &:hover {
           cursor: pointer;
        }
    }
}
</style>