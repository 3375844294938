<template>
     <div class="header" :class="$mq">
        <div class="description">
            <p class="explanation" :class="$mq">{{ $t('realised_with') }}</p>
            <img :src="getImgUrl()" alt="image of flag" class="flag" @click="changeLanguage"/>
        </div>

        <div class="content">
            <div class="image-container" :class="$mq">
                <img src="../../assets/me.png" alt="Photo of Quentin Louis" class="me"/>
            </div>
            <div class="text-container" :class="$mq">
                <h2 :class="$mq">Quentin Louis</h2>
                <p :class="$mq">{{ $t('developer') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data : () => {
        return {
            'image' : 'fr'
        }
    },
    methods : {
        changeLanguage(){
            if(this.image == 'fr'){
                this.image = 'en';
            }else{
                this.image = 'fr';
            }
            this.$i18n.locale = this.image;
        },
        getImgUrl(){
            var images = require.context('../../assets/', false, /\.png$/);
            if(this.image == 'en'){
                return images('./fr.png');
            }else{
                return images('./en.png');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.header {
    display: flex;
    flex-direction: column;
    background-color: #174062;
    height: 30%;
    border-radius: 10px 10px 0 0;
    padding: 10px;

    &.desktop {
        height : 25%;
    }
}

.description {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10%;

    .explanation {
        margin: 0px 10px;
        font-size: 0.6rem;
        font-style: italic;

        &.desktop {
            font-size: 0.8rem;
        }
    }

    
    .flag {
        width: 20px;
        height: 20px;
        margin-left: auto;
        margin-right: 10px;
        
        &:hover {
            cursor: pointer;
        }
    }
}

.content {
    display: flex;
    flex-direction: row;
    height: 90%;
    width: 100%;

    .image-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        width: 40%;
        margin-left: 10px;

        &.desktop {
            width : 30%;
            justify-content: flex-end;
        }

        .me {
            display: flex;
            height: auto;
            max-width: 100%;
            border-radius: 4px;
            padding: 5px;
            background-color: white;
        }
    }
    .text-container {
        width : 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding : 10px 0 0 10px;

        &.desktop {
            width: 65%;
            align-items: flex-start;
        }

        h2 {
            margin: 10px 0 0 0 ;
            font-size: 1.5rem;

            &.desktop {
                margin : 0;
                font-size: 3rem;
            }
        }

        p {
            &.desktop {
                margin-top: 10px;
                font-size: 1.8rem;
            }
        }
    }
}
</style>